import {useNuxtApp} from '#app'

export const useDayjs = () => {
  const config = useRuntimeConfig()
  const {dateTime: dayjsConfig} = config.public as {dateTime: GlobalDayjsConfig}
  const {$dayjs, $dayjsImportAndLoadLocale, $dayjsDayOfWeekLabels, $dayjsMonthLabel} = useNuxtApp()
  const {locale} = useI18n()
  const dayjs = $dayjs
  const importAndLoadLocale = $dayjsImportAndLoadLocale
  const dayOfWeekLabels = $dayjsDayOfWeekLabels
  const monthLabel = $dayjsMonthLabel

  // watch i18n locale change
  watch(locale, async (newLocale: string) => {
    if (!newLocale) return
    const mappedLocaleName = dayjsConfig.i18nMap[newLocale]
    const localeToLoad = mappedLocaleName === undefined ? newLocale : mappedLocaleName
    await importAndLoadLocale(localeToLoad)
  })

  return {dayjs, dayOfWeekLabels, monthLabel}
}
